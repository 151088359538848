.property-row {
    display: flex;
    transition: transform 0.5s ease-in-out;
  
    .property-col{
      width: 350px; 
    }
  }

  .grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  
    .grid-item.large-item {
      width: 100%; /* Full width on smaller screens */
      max-width: 600px; /* Maximum width for larger screens */
      height: 615px;
    }
  
    .grid-item.small-item {
      width: 100%; /* Full width on smaller screens */
      max-width: 300px; /* Maximum width for larger screens */
      height: 300px;
    }
  
    .overlay-item {
      width: 100%; /* Full width on smaller screens */
      max-width: 300px; /* Maximum width for larger screens */
      height: 300px;
    }

  }

  @media (max-width: 768px) {
    .grid-container {
      display: grid !important;
    }
    .grid-item.large-item {
      width: 100%; 
      grid-column: span 5 !important;
      height: 220px !important;
    }

    .grid-item.small-item {
      width: 100% !important; 
      grid-column: span 2 !important;
      height: 100px !important;
    }

    .overlay-item {
      width: 100% !important; 
      grid-column: span 5 !important;
      height: 100px !important;
    }
  }

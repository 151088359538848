.search-properties{
    .property-col{

        .property-card{
            width: 100% !important;
            position: relative;
          
            .property-image{
              position: relative;
              width: 100%;
            
            }

            .property-image-carousel {
                position: relative;
                width: 100% !important;
                height: 100% !important;
              
                .slick-slider {
                  width: 100% !important;
                  
              
                  .property-slider {
                    height: 260px !important;
                    border-radius: 10px;
                    background: #ddd;
                    img {
                      width: 100% !important;
                      height: 100% !important;
                      object-fit: cover !important;
                      border-radius: 10px;
                      transition: .5s;
                    }
                  }
      
                  .slick-prev,
                  .slick-next {
                    font-size: 24px;
                    line-height: 1;
                    color: #333;
                    z-index: 1;
      
                    &:hover {
                      color: red;
                    }
                  }
      
                  .slick-prev {
                    left: 10px;
                  }
      
                  .slick-next {
                    right: 10px; 
                  }
              
                  .slick-dots {
                    bottom: 20px;
                    list-style: none;
                    display: flex;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
              
                    li {
                      margin: 0px;
                      border-radius: 50%;
                      cursor: pointer;
                      transition: background-color 0.3s;
                      // &:hover,
                      // &.slick-active {
                      //   background-color: $primary;
                      // }
                    }
                  }
                }
              }

              .property-agent{
                position: absolute !important;
                width: 50px !important;
                height: 50px !important;
                border-radius: 1000px !important;
                border: 2px solid #fff !important;
                background: #c84f45 !important;
                bottom: 60px !important;
                left: 20px !important;
                
            
                img{
                  width: 100%;
                  height: 100%;
                  border-radius: 1000px;
                  border: 2px solid #fff;
                }
              }

              .label {
                position: absolute;
                background: #fff;
                border-radius: 50px;
                color: #000;
                padding: 10px;
                top: 20px;
                left: 20px;
                box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;
              }

              .details{
                padding: 10px;
                display: flex;
                justify-content: space-between;
            
                .property-name{
                font-weight: bold;
                a{
                  color: #c84f45;
                }
                }
            
                .property-price{
                font-weight: bold;
                }
              }

              .address{
                margin-top: -10px;
                color: #333;
                font-size: 14px;
                position: relative;
                left: 5px;
              }
        }
    }
}
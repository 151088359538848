.grid-container {
    display: flex;
    justify-content: center;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
  
  .grid-item {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    display: block !important;

    .overlay-item{
        position: relative;
        width: 100%;
        height: 100%;
        .overlay{
            background: rgb(0, 0, 0, .7);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1 !important;
            display: flex;
            justify-content: center;
            align-items: center;

            div{
                font-size: 36px;
                color: #fff;
            }
        }
        a{
            position: absolute;
            display: block;
            height: 100%;

            &:first-child {
                // position: relative !important;
                top: 0;
                left: 0;
                z-index: 1 !important
              }

              img{
                height: 100% !important;
                object-fit: cover;
              }
        }
      }
  }
  
  .large-item {
    grid-row: span 2;
    grid-column: span 2;
  }

 
  
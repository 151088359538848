.ui.small.stackable.menu{
    display: flex !important;
    justify-content: center !important;

    @media (max-width: 1028px) { 
      
    }
}

.toggleMenu{
  display: none !important;
}
.logo{
    position: relative !important;
    right: 10px;

    img{
        position: absolute !important;
        width: 100px !important;
    }
}

.page-wrapper {
    position: relative;
  }
  
  .dimmed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for the desired dimming effect */
    pointer-events: none;
    transition: background-color 0.3s ease-in-out;
    z-index: 1000; /* Adjust the z-index as needed to make sure it's above other elements */
  }
  
  .page-wrapper.dimmed::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
  

  .search-result{
    background: #fff;
    width: 400px;
    height: 300px auto;
    position: absolute !important;
    top: 55px;
    z-index: 1000000 !important;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .search-result-list{
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .search-result-item{
        display: flex;
        justify-content: start;
        padding: 19px;
        color: #333 !important;
        border-bottom: 1px solid #eee;
        &:hover{
                  background: #f5f5f5;
              }
      }

      .search-result-item-image{
        width: 50px;
        height: 50px;
        background: #eee;
        border-radius: 5px;
        margin-right: 10px;

        img{
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }
    }
    .search-result-title{
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .search-result-content{
      font-size: 1em;
      margin-bottom: 10px
  }


  }
  
 
  
.MobileNav{
  background: #fff;
  padding: 10px;
  position: relative;

  .topHeader{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo{
      width: 80px;
    }
  }

  .menu{
    position: relative;
    width: 100%;

  }

  .userItem {
    i{
      display: none !important;
    }
  }

}
  
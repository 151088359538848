.login-container {
    position: relative;
    width: 100%;
    height: 100vh;

    .login-background::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/assets/images/home-bg.jpg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        filter: blur(3px); /* Adjust the blur amount as needed */
       

        .login-content {
            position: relative;
          }
      }
  }


.box{
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    position: relative;
    z-index: 1;
}
footer.footer{
    margin-top: 20px;
}

.Footer{
    padding-top: 30px;

    .footer-hero {
        position: relative;
        width: 100%;
        height: 200px;
        background-image: url('/assets/images/texture.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
      
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
        }
      
        h4 {
          position: relative;
          color: white;
          z-index: 1;
          font-size: 30px;
          font-weight: bold;
        }
      }     
}

.footer-img{
	
	img{
		width: 100px;
	}
}

ul li a{
	color: #333;

	&:hover{
		color: #ddd;
	}
}

@media(max-width: 767px){
  .footer-logo{
    width: 150px !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    left: -30px;
  }
}